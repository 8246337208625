import 'jquery'
import 'bootstrap'
import 'select2'
import IMask from 'imask'
import 'air-datepicker'
import 'tabbyjs'

document.addEventListener('DOMContentLoaded', () => {
  $('select').select2({ language: 'ru', minimumResultsForSearch: Infinity })

  const inputPhones = document.querySelectorAll(
    'input[data-input-type="phone"]'
  )

  inputPhones?.forEach(inputPhone => {
    IMask(inputPhone, { mask: '+{7} (000) 000-00-00' })
  })

  const resetBtns = document.querySelectorAll('[data-reset-radio]')

  resetBtns?.forEach(resetBtn => {
    resetBtn.addEventListener('click', e => {
      e.preventDefault()

      const radioButtonsGroupName = e.target.getAttribute('data-reset-radio')

      const radioButtons = document.querySelectorAll(
        `input[type="radio"][name="${radioButtonsGroupName}"]`
      )

      radioButtons?.forEach(radioButton => {
        radioButton.checked = false
      })
    })
  })

  $('.datepicker-input[data-datepicker-type="all"]').datepicker({
    language: 'ru',
    autoClose: true,
  })

  $('.datepicker-input[data-datepicker-type="years"]').datepicker({
    language: 'ru',
    view: 'years',
    minView: 'years',
    dateFormat: 'yyyy',
    autoClose: true,
  })

  $('.datepicker-input[data-datepicker-type="months"]').datepicker({
    language: 'ru',
    view: 'months',
    minView: 'months',
    dateFormat: 'MM',
    autoClose: true,
    onShow: function (dp, animationCompleted) {
      dp.$nav.first().hide()
    },
  })

  $('.datepicker-input[data-datepicker-type="years-and-months"]').datepicker({
    language: 'ru',
    view: 'months',
    minView: 'months',
    dateFormat: 'MM, yyyy',
    autoClose: true,
  })

  if (document.querySelector('[data-tabs]')) {
    new Tabby('[data-tabs]')
  }

  const frTabs = document.querySelectorAll('[data-fr-tabs]')

  frTabs?.forEach(tabs => {
    const tabsID = tabs.getAttribute('data-fr-tabs')

    tabs.querySelectorAll('[data-fr-tabs-target]')?.forEach(tab => {
      if (!tab.getAttribute('data-fr-tabs-default')) {
        const id = tab.getAttribute('data-fr-tabs-target')

        document
          .querySelectorAll(`[data-fr-tabs-targeted="${id}"]`)
          ?.forEach(el => {
            el.style.display = 'none'
          })
      } else {
        tab.setAttribute('aria-selected', 'true')
      }

      tab.addEventListener('click', e => {
        e.preventDefault()

        const tabID = tab.getAttribute('data-fr-tabs-target')

        tab
          .closest('[data-fr-tabs]')
          .querySelectorAll(
            `[data-fr-tabs-target]:not([data-fr-tabs-target="${tabID}"])`
          )
          .forEach(el => {
            document
              .querySelectorAll(
                `[data-fr-tabs-targeted="${el.getAttribute(
                  'data-fr-tabs-target'
                )}"]`
              )
              .forEach(targeted => {
                targeted.style.display = 'none'
              })
          })

        document
          .querySelectorAll(`[data-fr-tabs-targeted="${tabID}"]`)
          .forEach(el => {
            if (el.nodeName !== 'TR' && el.nodeName !== 'TD') {
              el.style.display = 'block'
            } else if (el.nodeName == 'TR') {
              el.style.display = 'table-row'
            } else if (el.nodeName == 'TD') {
              el.style.display = 'table-cell'
            }
          })

        tab
          .closest('[data-fr-tabs]')
          .querySelector('[aria-selected="true"]')
          .setAttribute('aria-selected', 'false')

        tab.setAttribute('aria-selected', 'true')
      })
    })
  })

  const toggleCheckboxes = document.querySelectorAll(
    'input[type="checkbox"][data-toggle="collapse"]:checked'
  )

  toggleCheckboxes?.forEach(toggleCheckbox => {
    const targetID = toggleCheckbox.getAttribute('data-target')

    document.querySelector(targetID)?.classList.add('show')
  })
})
